import { CURRENT_ENV } from 'config/config';
import { Market } from 'generated/event.types';

type StaticBiogenLincLinkMapper = { [key: string]: string };
export const previewDomainLinks: StaticBiogenLincLinkMapper = {
  fr: 'https://fr.preview.mybiogen.io/events-list/',
  de: 'https://de.preview.mybiogen.io/events-list/',
  gb: 'https://uk.preview.mybiogen.io/events-list/',
  ie: 'https://ie.preview.mybiogen.io/events-list/',
  es: 'https://es.preview.mybiogen.io/events-list/',
  at: 'https://at.preview.mybiogen.io/events-list/',
  be: 'https://be.preview.mybiogen.io/events-list/',
  ch: 'https://ch.preview.mybiogen.io/events-list/',
  dk: 'https://dk.preview.mybiogen.io/events-list/',
  nl: 'https://nl.preview.mybiogen.io/events-list/',
  pl: 'https://pl.preview.mybiogen.io/events-list/',
  pt: 'https://pt.preview.mybiogen.io/events-list/',
  au: 'https://au.preview.mybiogen.io/events-list/',
  br: 'https://br.preview.mybiogen.io/events-list/',
  ca: 'https://ca.preview.mybiogen.io/events-list/',
  mx: 'https://mx.preview.mybiogen.io/events-list/',
  jp: 'https://jp.preview.mybiogen.io/events-list/',
  com: 'https://com.preview.mybiogen.io/events-list/',
  gcc: 'https://gulf.preview.mybiogen.io/events-list/',
};

const localUrl = 'https://app.mybiogen.io:3000';

const devDomainLinks = {
  [Market.at]: 'https://at.dev.mybiogen.io',
  [Market.au]: 'https://au.dev.mybiogen.io',
  [Market.be]: 'https://be.dev.mybiogen.io',
  [Market.br]: 'https://br.dev.mybiogen.io',
  [Market.ca]: 'https://ca.dev.mybiogen.io',
  [Market.com]: 'https://com.dev.mybiogen.io',
  [Market.de]: 'https://de.dev.mybiogen.io',
  [Market.es]: 'https://es.dev.mybiogen.io',
  [Market.fr]: 'https://fr.dev.mybiogen.io',
  [Market.gb]: 'https://uk.dev.mybiogen.io',
  [Market.ie]: 'https://uk.dev.mybiogen.io',
  [Market.jp]: 'https://jp.dev.mybiogen.io',
  [Market.mx]: 'https://mx.dev.mybiogen.io',
  [Market.pt]: 'https://pt.dev.mybiogen.io',
  [Market.ch]: 'https://ch.dev.mybiogen.io',
  [Market.nl]: 'https://nl.dev.mybiogen.io',
  [Market.pl]: 'https://pl.dev.mybiogen.io',
  [Market.dk]: 'https://dk.dev.mybiogen.io',
  [Market.us]: 'https://com.dev.mybiogen.io',
  [Market.gcc]: 'https://gulf.dev.mybiogen.io',
};

const stageDomainLinks = {
  [Market.at]: 'https://at.preprod.mybiogen.io',
  [Market.au]: 'https://au.preprod.mybiogen.io',
  [Market.be]: 'https://be.preprod.mybiogen.io',
  [Market.br]: 'https://br.preprod.mybiogen.io',
  [Market.ca]: 'https://ca.preprod.mybiogen.io',
  [Market.com]: 'https://com.preprod.mybiogen.io',
  [Market.de]: 'https://de.preprod.mybiogen.io',
  [Market.es]: 'https://es.preprod.mybiogen.io',
  [Market.fr]: 'https://fr.preprod.mybiogen.io',
  [Market.gb]: 'https://uk.preprod.mybiogen.io',
  [Market.ie]: 'https://uk.preprod.mybiogen.io',
  [Market.jp]: 'https://jp.preprod.mybiogen.io',
  [Market.mx]: 'https://mx.preprod.mybiogen.io',
  [Market.pt]: 'https://pt.preprod.mybiogen.io',
  [Market.ch]: 'https://ch.preprod.mybiogen.io',
  [Market.nl]: 'https://nl.preprod.mybiogen.io',
  [Market.pl]: 'https://pl.preprod.mybiogen.io',
  [Market.dk]: 'https://dk.preprod.mybiogen.io',
  [Market.us]: 'https://com.preprod.mybiogen.io',
  [Market.gcc]: 'https://gulf.preprod.mybiogen.io',

};

export const prodDomainLinks: StaticBiogenLincLinkMapper = {
  [Market.at]: 'https://biogenlinc.at',
  [Market.au]: 'https://biogenlinc.com.au',
  [Market.be]: 'https://biogenlinc.be',
  [Market.br]: 'https://biogenlinc.com.br',
  [Market.ca]: 'https://biogenlinc.ca',
  [Market.com]: 'https://biogenlinc.com',
  [Market.de]: 'https://biogenlinc.de',
  [Market.es]: 'https://biogenlinc.es',
  [Market.fr]: 'https://biogenlinc.fr',
  [Market.gb]: 'https://biogenlinc.co.uk',
  [Market.ie]: 'https://biogenlinc.co.uk',
  [Market.jp]: 'https://biogenlinc.jp',
  [Market.mx]: 'https://biogenlinc.mx',
  [Market.pt]: 'https://biogenlinc.pt',
  [Market.ch]: 'https://biogenlinc.ch',
  [Market.nl]: 'https://biogenlinc.nl',
  [Market.pl]: 'https://biogenlinc.pl',
  [Market.dk]: 'https://biogenlinc.dk',
  [Market.us]: 'https://biogenlinc.com',
  [Market.gcc]: 'https://gcc.biogenlinc.com',
};

export const getBaseUrlPerMarket = (market: Market) => {
  switch (CURRENT_ENV) {
    case 'local': {
      return localUrl;
    }
    case 'dev': {
      return devDomainLinks[market as keyof typeof devDomainLinks];
    }
    case 'stage': {
      return stageDomainLinks[market as keyof typeof stageDomainLinks];
    }
    default:
      return prodDomainLinks[market as keyof typeof prodDomainLinks];
  }
};
