import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { FileExtension } from 'basics/types/files.types';
import { AssetTarget, AssetType } from 'generated/event.types';
import { commonFields } from 'modules/events/basics/constants/commonFormFields.constants';
import { EventBiogenBrand } from 'modules/events/basics/enums/biogen.enum';
import useBiogenBrandsOptions from 'modules/events/basics/options/biogenBrands.options';
import { EventStateType } from 'modules/events/basics/types/events.types';
import usePrimaryInformationsSchema from 'modules/events/groups/PrimaryInformationsGroup/PrimaryInformationsGroup.schema';
import { PrimaryInformationsType } from 'modules/events/groups/PrimaryInformationsGroup/PrimaryInformationsGroup.types';
import eventState from 'modules/events/states/events.states';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import { mapToSelectOptions } from 'yoda-ui/utils/options.utils';

const usePrimaryInformationsConfig = () => {
  const { t } = useTranslation();
  const snap = useSnapshot(eventState) as EventStateType;
  const { programs } = snap;

  const mapProgramOptions = useCallback(mapToSelectOptions, [programs]);

  const primaryInformationsSchema = usePrimaryInformationsSchema();
  const primaryInformationsConfig: PrimaryInformationsType = {

    title: {
      label: t('events_title_input_label'),
      name: commonFields.title,
      placeholder: t('events_title_input_placeholder'),
      required: true,
      defaultValue: snap.createEvent.title,
      validation: primaryInformationsSchema.title,
    },
    description: {
      label: t('events_description_input_label'),
      name: commonFields.description,
      placeholder: t('events_description_input_placeholder'),
      required: true,
      defaultValue: snap.createEvent.description,
      validation: primaryInformationsSchema.description,
    },
    product: {
      label: t('events_product_input_label'),
      name: commonFields.product,
      placeholder: t('events_product_input_placeholder'),
      options: useBiogenBrandsOptions(EventBiogenBrand),
      required: true,
      defaultValue: snap.createEvent.data?.product,
      validation: primaryInformationsSchema.product,
      disabled: true,
    },
    program: {
      label: t('events_program_input_label'),
      name: commonFields.program,
      placeholder: t('events_program_input_placeholder'),
      options: mapProgramOptions(programs.data.map((item) => item.programName)),
      required: false,
      defaultValue: snap.createEvent.data?.program,
      validation: primaryInformationsSchema.program,
    },
    cardImage: {
      name: commonFields.cardImage,
      cardImageFormConfig: {
        btnLabel: t('events_image_dropzone_action_label'),
        btnIcon: Icons.upload,
        maxFileSize: 716800,
        maxFilesNumber: 1,
        extensionsAllowed: [FileExtension.jpg, FileExtension.jpeg, FileExtension.png],
        message: t('events_image_dropzone_message'),
        label: t('events_image_input_label'),
        name: commonFields.cardImage,
        assetTarget: AssetTarget.cardImage,
        assetType: AssetType.image,
        assetsArePublic: true,
      },
      listConfig: {
        noItemLabel: t('asset_upload_no_image_label'),
        title: t('asset_upload_image_title'),
      },
      dimensions: {
        maxHeight: 400,
        maxWidth: 600,
        minHeight: 300,
        minWidth: 600,
      },
      validation: primaryInformationsSchema.cardImage,
      defaultValue: snap.createEvent.data?.cardImage || [],
    },
  };

  return { primaryInformationsConfig };
};

export default usePrimaryInformationsConfig;
