import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  EMAIL_CONFIG_LOGOS,
  EMAIL_CONFIG_BUTTONS_COLOR,
  EMAIL_CONFIG_CF_URL,
  EMAIL_CONFIG_FOOTER_NOTE,
  EMAIL_CONFIG_HEADER_NOTE,
  EMAIL_CONFIG_MAYA_TACTICS,
} from 'basics/constants/images.constants';
import CardForm from 'components/CardForm';
import CxSurvey from 'components/CxSurvey/CxSurvey';
import { Presence } from 'generated/event.types';
import { TacticStatus } from 'modules/events/basics/enums/maya.enums';
import useTacticOptions from 'modules/events/basics/options/tactic.options';
import CardImage from 'modules/events/components/CardImage';
import InformativeTooltip from 'modules/events/components/InformativeTooltip';
import useEmailNotes from 'modules/events/groups/EmailNotesGroup/EmailNotesGroup.hook';
import Box from 'yoda-ui/components/Box';
import CheckBoxYoda from 'yoda-ui/components/Form/CheckBoxYoda';
import RadioGroup from 'yoda-ui/components/Form/RadioGroup';
import RichtextYoda from 'yoda-ui/components/Form/RichtextYoda';
import Select from 'yoda-ui/components/Form/Select/Select';
import { labelStyle, warningLabelStyle } from 'yoda-ui/components/Form/Select/Select.styles';
import SelectAsync from 'yoda-ui/components/Form/SelectAsync';
import SelectYoda from 'yoda-ui/components/Form/SelectYoda';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import MenuItem from 'yoda-ui/components/MenuItem';
import { YodaFontSize, YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const EmailNotesGroup = () => {
  const {
    selectedLogo,
    selectedCEP,
    handleDropImage,
    emailNotesConfig,
    buttonColorConfig,
    cepChanged,
    changeColorValue,
    eventPresence,
    t,
  } = useEmailNotes();
  const { eventsBiogenlinc4844CxAutomationEmailConfigurationSection: displayCxAutomationSection } = useFlags();
  const mayaOptions = useTacticOptions(String(selectedCEP?.id), undefined, [TacticStatus.APPROVED]).map((tactic) => {
    return { id: tactic.formattedId, label: `${tactic.label} (${tactic.formattedId})` };
  });

  const displayLogoWarningMessage = selectedLogo === '0';

  return (
    <Box>
      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_email_contentful_url_title') }
          tooltipTitle={ t('events_email_contentful_url_tooltip') }
          tooltipImageUrl={ EMAIL_CONFIG_CF_URL }>
          <Box marginBottom={ YodaSpacing.large }>
            <TextYoda { ...emailNotesConfig.emailContentfulUrl } />
          </Box>
        </CardForm>
      </Box>

      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_email_notes_title') }>
          <Box marginBottom={ YodaSpacing.large }>
            <InformativeTooltip { ...{ title: t('events_email_header_note_tooltip'), imageUrl: EMAIL_CONFIG_HEADER_NOTE } }/>
            <Box justifyContent={ 'right' } marginLeft={ '70%' }>
              <MultiSelectYoda { ...emailNotesConfig.emailHeaderNoteEmailTypes }/>
            </Box>
            <RichtextYoda { ...emailNotesConfig.emailHeaderNote } />
          </Box>

          <Box marginBottom={ YodaSpacing.large }>
            <InformativeTooltip { ...{ title: t('events_email_footer_note_tooltip'), imageUrl: EMAIL_CONFIG_FOOTER_NOTE } }/>
            <Box justifyContent={ 'right' } marginLeft={ '70%' }>
              <MultiSelectYoda { ...emailNotesConfig.emailFooterNoteEmailTypes }/>
            </Box>
            <RichtextYoda { ...emailNotesConfig.emailFooterNote } />
          </Box>
          <Box marginBottom={ YodaSpacing.large }>
            <RichtextYoda { ...emailNotesConfig.emailFooterDisclaimerText } />
          </Box>
        </CardForm>
      </Box>

      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_email_logo_title') }>
          <Box fontWeight={ YodaFontWeight.light } paddingBottom={ YodaSpacing.large }>
            <CardImage
              handleFiles={ handleDropImage }
              { ...emailNotesConfig.programLogo }
            />
          </Box>

          <Box paddingBottom={ YodaSpacing.small }>
            <Box fontSize={ YodaFontSize.large } fontWeight={ YodaFontWeight.medium } paddingBottom={ YodaSpacing.large }>
              { emailNotesConfig.biogenLogo.selectTargetList.title }
              <InformativeTooltip { ...{ title: `${t('events_email_both_logos_tooltip')} 
              \n ${t('events_email_one_logo_tooltip')}
              \n${t('events_email_no_logos_tooltip')}`,
              imageUrl: EMAIL_CONFIG_LOGOS } }/>
            </Box>
            <Box hidden={ !displayLogoWarningMessage }>
              <p style={ warningLabelStyle }>{ t('events_email_no_logo_warning') }</p>
            </Box>
            <RadioGroup { ...emailNotesConfig.biogenLogo.selectTargetList } />
          </Box>

        </CardForm>
      </Box>

      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_email_buttons_title') }
          tooltipTitle={ t('events_email_color_tooltip') }
          tooltipImageUrl={ EMAIL_CONFIG_BUTTONS_COLOR }>
          <Box marginBottom={ YodaSpacing.large }>
            <p style={ labelStyle }>{ t('events_email_buttons_color_label') }</p>
            <Select
              name={ buttonColorConfig.name }
              value={ buttonColorConfig.value }
              onChange={ (event) => changeColorValue(String(event.target.value)) }
            >
              {
                buttonColorConfig.options.map((option) => (
                  <MenuItem key={ option.colorHex } value={ option.colorHex }>
                    <Box
                      borderRadius={ 8 }
                      bgcolor={ option.colorHex }
                      width={ 40 }
                      height={ 40 }
                      marginRight={ YodaSpacing.medium }
                    >
                    </Box>
                    <Box
                      fontSize={ YodaFontSize.large }
                      fontWeight={ YodaFontWeight.medium }
                    >
                      { option.colorLabel }
                    </Box>
                  </MenuItem>
                ))
              }
            </Select>
          </Box>
        </CardForm>
      </Box>

      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_email_prcValidation_title') }>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={ YodaSpacing.medium }
          >
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              { t('events_email_prcValidation_label') }
            </Box>

          </Box>

          <TextYoda { ...emailNotesConfig.emailPrcCode } />
        </CardForm>
      </Box>
      {
        displayCxAutomationSection
      && <Box paddingBottom={ YodaSpacing.small }>
        <CxSurvey />
      </Box>
      }
      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_email_team_contact_title') }>
          <Box marginBottom={ YodaSpacing.medium }>
            <CheckBoxYoda
              { ...emailNotesConfig.teamContactCheckbox }
            />
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <TextYoda
              { ...emailNotesConfig.teamContactEmail }
            />
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <RichtextYoda
              { ...emailNotesConfig.teamContactDescription }
            />
          </Box>
        </CardForm>
      </Box>
      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_email_mayaTactics_title') }
          tooltipTitle={ t('events_email_mayaTactics_tooltip') }
          tooltipImageUrl={ EMAIL_CONFIG_MAYA_TACTICS }>
          <Box paddingBottom={ YodaSpacing.small }>
            <SelectAsync
              onChange={ cepChanged }
              { ...emailNotesConfig.CEPsearch } />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={ YodaSpacing.medium }
          >
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              { t('events_email_mayaTactics_invitation_email_label') }
            </Box>
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            {
              selectedCEP
                ? <SelectYoda options={ mayaOptions } { ...emailNotesConfig.invitationEmailMayaId } />
                : <TextYoda { ...emailNotesConfig.invitationEmailMayaId } />
            }
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <TextYoda { ...emailNotesConfig.invitationEmailUTM } />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={ YodaSpacing.medium }
          >
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              { t('events_email_mayaTactics_confirmation_email_label') }
            </Box>
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            {
              selectedCEP
                ? <SelectYoda options={ mayaOptions } { ...emailNotesConfig.confirmationEmailMayaId } />
                : <TextYoda { ...emailNotesConfig.confirmationEmailMayaId } />
            }
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <TextYoda { ...emailNotesConfig.confirmationEmailUTM } />
          </Box>

          {
            eventPresence !== Presence.physical && (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  marginBottom={ YodaSpacing.medium }
                >
                  <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
                    { t('events_email_mayaTactics_reminder48h_label') }
                  </Box>
                </Box>
                <Box marginBottom={ YodaSpacing.xSmall }>
                  {
                    selectedCEP
                      ? <SelectYoda options={ mayaOptions } { ...emailNotesConfig.reminder48hMayaId } />
                      : <TextYoda { ...emailNotesConfig.reminder48hMayaId } />
                  }
                </Box>
                <Box marginBottom={ YodaSpacing.xSmall }>
                  <TextYoda { ...emailNotesConfig.reminder48hUTM } />
                </Box>
              </>
            )
          }
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={ YodaSpacing.medium }
          >
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              {
                eventPresence === Presence.physical
                  ? t('events_email_mayaTactics_reminder1w_label') : t('events_email_mayaTactics_reminder24h_label')
              }
            </Box>
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            {
              selectedCEP
                ? <SelectYoda options={ mayaOptions } { ...emailNotesConfig.reminder24hMayaId } />
                : <TextYoda { ...emailNotesConfig.reminder24hMayaId } />
            }
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <TextYoda { ...emailNotesConfig.reminder24hUTM } />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={ YodaSpacing.medium }
          >
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              {
                eventPresence === Presence.physical
                  ? t('events_email_mayaTactics_reminder1d_label') : t('events_email_mayaTactics_reminder12h_label')
              }
            </Box>
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            {
              selectedCEP
                ? <SelectYoda options={ mayaOptions } { ...emailNotesConfig.reminder12hMayaId } />
                : <TextYoda { ...emailNotesConfig.reminder12hMayaId } />
            }
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <TextYoda { ...emailNotesConfig.reminder12hUTM } />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={ YodaSpacing.medium }
          >
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              { t('events_email_mayaTactics_reminder1h_label') }
            </Box>
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            {
              selectedCEP
                ? <SelectYoda options={ mayaOptions } { ...emailNotesConfig.reminder1hMayaId } />
                : <TextYoda { ...emailNotesConfig.reminder1hMayaId } />
            }
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <TextYoda { ...emailNotesConfig.reminder1hUTM } />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={ YodaSpacing.medium }
          >
            <Box fontSize={ YodaFontSize.medium } fontWeight={ YodaFontWeight.medium }>
              { t('events_email_mayaTactics_cx_survey_label') }
            </Box>
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            {
              selectedCEP
                ? <SelectYoda options={ mayaOptions } { ...emailNotesConfig.cxSurveyMayaId } />
                : <TextYoda { ...emailNotesConfig.cxSurveyMayaId } />
            }
          </Box>
          <Box marginBottom={ YodaSpacing.xSmall }>
            <TextYoda { ...emailNotesConfig.cxSurveyUTM } />
          </Box>
        </CardForm>
      </Box>

      <Box paddingBottom={ YodaSpacing.small }>
        <CardForm title={ t('events_reminder_journey_configuration_label') }>
          <Box marginBottom={ YodaSpacing.medium }>
            <MultiSelectYoda { ...emailNotesConfig.reminderNotificationEmails }/>
          </Box>
          <Box marginBottom={ YodaSpacing.medium }>
            <MultiSelectYoda { ...emailNotesConfig.reminderNotificationPhoneNumbers }/>
          </Box>
        </CardForm>
      </Box>

    </Box>
  );
};

export default EmailNotesGroup;
